<form (ngSubmit)="onRegisterFormSubmit()" [formGroup]="registerForm">
  <div class="formgrid grid p-fluid">
    <ng-container *ngFor="let control of controlList">
      <div
        class="field mb-4 col-12 md:col-{{
          control.width ? control.width : 12
        }}"
      >
        <label
          *ngIf="control.type != 'checkbox'"
          for="{{ control.name }}"
          class="font-medium text-900"
          >{{ control.label
          }}<span *ngIf="control.validators.required" class="text-red-600"
            >*</span
          ></label
        >
        <small
          *ngIf="control.type != 'checkbox'"
          class="font-light text-400 text-sm ml-2"
          >{{ control.description }}</small
        >
        <input
          *ngIf="control.type === 'text'"
          [id]="control.name"
          type="text"
          pInputText
          [formControlName]="control.name"
          (blur)="registerForm.controls[control.name].markAsDirty()"
        />
        <input
          *ngIf="control.type === 'email'"
          [id]="control.name"
          type="email"
          pInputText
          [formControlName]="control.name"
          (blur)="registerForm.controls[control.name].markAsDirty()"
          pKeyFilter="email"
        />
        <input
          *ngIf="control.type === 'tel'"
          [id]="control.name"
          type="tel"
          pInputText
          [formControlName]="control.name"
          (blur)="registerForm.controls[control.name].markAsDirty()"
        />
        <!-- [pValidateOnly]="true"
        [pKeyFilter]="ukPhoneNumberRegex"
        [pattern]="ukPhoneNumberRegex" -->
        <p-checkbox
          [id]="control.name"
          *ngIf="control.type === 'checkbox'"
          [binary]="true"
          [value]="false"
          [label]="control.label"
          labelStyleClass="text-900 font-medium"
          [formControlName]="control.name"
        ></p-checkbox>
      </div>
    </ng-container>
    <div class="field mb-4 col-12 md:col-6">
      <button
        type="submit"
        pButton
        pRipple
        pButton
        label="Register"
        class="p-button-primary"
        [disabled]="!registerForm.valid"
      ></button>
    </div>
  </div>
</form>
