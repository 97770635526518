import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReceiversService {
  constructor(private apiService: ApiService) {}

  getReceivers() {
    return this.apiService.getAPI('receivers');
  }

  getReceiverByUniqueId(unique_id: string) {
    return this.apiService
      .getAPI(`receivers/${unique_id}`)
      .pipe(map((res: any) => res.body));
  }

  createReceiver(receiver: any) {
    return this.apiService.postAPI('receivers', receiver);
  }

  updateReceiver(receiver_id: number, receiver: any) {
    return this.apiService.putAPI('receivers', receiver_id, receiver);
  }

  updateReceiverByUniqueId(unique_id: string, receiver: any) {
    return this.apiService.postAPI(`receivers/${unique_id}`, receiver);
  }

  deleteReceiver(receiver_id: number) {
    return this.apiService.deleteAPI('receivers', receiver_id);
  }
}
