import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-own-amount-dialog',
  templateUrl: './own-amount-dialog.component.html',
  styleUrls: ['./own-amount-dialog.component.scss'],
})
export class OwnAmountDialogComponent implements OnInit {
  amount: number = 5;

  constructor(
    private fb: FormBuilder,
    public config: DynamicDialogConfig,
    private ref: DynamicDialogRef
  ) {}

  ownAmountForm = this.fb.group({
    amount: [5],
  });

  ngOnInit(): void {
    // focus pinput number
    const input = document.getElementById('amount');
    if (input) {
      input.focus();
    }
  }

  // incrementAmount() {
  //   this.amount = this.ownAmountForm.value.amount
  //     ? this.ownAmountForm.value.amount
  //     : 0;
  //   if (this.amount < 300) {
  //     this.amount++;
  //     this.ownAmountForm.patchValue({ amount: this.amount });
  //   }
  // }

  // decrementAmount() {
  //   this.amount = this.ownAmountForm.value.amount
  //     ? this.ownAmountForm.value.amount
  //     : 0;
  //   if (this.amount > 0) {
  //     this.amount--;
  //     this.ownAmountForm.patchValue({ amount: this.amount });
  //   }
  // }

  clearAmount() {
    this.ownAmountForm.patchValue({ amount: 5 });
    this.amount = 5;
  }

  onSubmitOwnAmount() {
    console.log('form values', this.ownAmountForm.value);
    console.log('form valid', this.ownAmountForm.valid);
    if (this.ownAmountForm.valid) {
      this.ref.close({ amount: this.ownAmountForm.value.amount });
    }
  }
}
