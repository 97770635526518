<div class="flex flex-column align-items-center my-4">
  <span
    class="flex align-items-center justify-content-center {{
      config.data.status === 'success' ? 'bg-primary' : 'bg-red-400'
    }} text-cyan-800 mr-3 border-circle mb-3"
    style="width: 64px; height: 64px"
  >
    <i
      class="pi {{
        config.data.status === 'success' ? 'pi-check' : 'pi-times'
      }} text-5xl  text-white
      "
    ></i>
  </span>
  <!-- <div class="font-medium text-2xl text-900">{{ this.config.data | json }}</div> -->
</div>
<p
  *ngIf="config.data.status === 'success'"
  class="line-height-3 p-0 m-0 text-xl text-gray-600 text-center"
>
  Your payment for the order has been successfully processed and your access
  provisioned for the.
</p>
<p
  *ngIf="config.data.status === 'failed'"
  class="line-height-3 p-0 m-0 text-xl text-gray-600 text-center"
>
  Your payment for the order has been successfully processed and your access
  provisioned for the.
</p>
