<form (ngSubmit)="onSubmitOwnAmount()" [formGroup]="ownAmountForm">
  <div class="formgrid grid fluid py-4">
    <div class="field col-12 md:col-12">
      <label for="amount">Amount</label>
      <div class="p-inputgroup">
        <span class="p-inputgroup-addon">R</span>
        <p-inputNumber
          pAutoFocus
          [(ngModel)]="amount"
          mode="decimal"
          inputId="amount"
          class="w-full"
          [maxFractionDigits]="2"
          [minFractionDigits]="2"
          [min]="5"
          [max]="1000"
          [showButtons]="true"
          formControlName="amount"
        >
        </p-inputNumber>
        <button
          type="button"
          pButton
          pRipple
          icon="pi pi-cross"
          styleClass="p-button-warning"
          label="Clear"
          (click)="clearAmount()"
        ></button>
      </div>
    </div>
    <div class="field col-12 md:col-12">
      <button
        type="submit"
        pButton
        pRipple
        pButton
        label="Pay R{{ amount | number : '1.2-2' }}"
        class="p-button-primary w-full"
        [disabled]="!ownAmountForm.valid"
      ></button>
    </div>
  </div>
</form>
