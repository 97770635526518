import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-register-receiver',
  templateUrl: './register-receiver.component.html',
  styleUrls: ['./register-receiver.component.scss'],
})
export class RegisterReceiverComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    public config: DynamicDialogConfig,
    private ref: DynamicDialogRef
  ) {}

  registerForm: any = this.fb.group({
    first_name: ['', [Validators.required]],
    last_name: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    mobile_phone: ['', [Validators.required]],
    unique_id: ['', [Validators.required]],
    id: [0, [Validators.required]],
  });

  controlList: any[] = [
    {
      name: 'first_name',
      label: 'First Name',
      description: 'Please enter your first name',
      value: '',
      type: 'text',
      width: 6,
      options: [],
      validators: { required: true },
    },
    {
      name: 'last_name',
      label: 'Last Name',
      description: 'Please enter your last name',
      value: '',
      type: 'text',
      width: 6,
      options: [],
      validators: { required: true },
    },
    {
      name: 'email',
      label: 'Email',
      description: 'Please enter your email',
      value: '',
      type: 'email',
      width: 6,
      options: [],
      validators: { required: true, email: true },
    },
    {
      name: 'mobile_phone',
      label: 'Mobile Phone',
      description: 'Please enter your mobile phone',
      value: '',
      type: 'tel',
      width: 6,
      options: [],
      validators: { required: true },
    },

    // {
    //   name: 'has_bank_account',
    //   label: 'Do you have a bank account?',
    //   description: 'Please enter your mobile phone',
    //   value: '',
    //   type: 'checkbox',
    //   width: 6,
    //   options: [],
    //   validators: { required: true },
    // },
  ];

  ngOnInit(): void {
    console.log('config', this.config.data);
    this.registerForm.patchValue({
      id: this.config.data.receiver.id,
      unique_id: this.config.data.receiver.unique_id,
    });
  }

  onRegisterFormSubmit() {
    console.log('form valid:', this.registerForm.valid);
    console.log('form value:', this.registerForm.value);

    if (this.registerForm.valid) {
      console.log('form is valid');
      this.ref.close(this.registerForm.value);
    } else {
      console.log('form is invalid');
    }
  }
}
