import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { BehaviorSubject } from 'rxjs';
import { OwnAmountDialogComponent } from '../dialogs/own-amount-dialog/own-amount-dialog.component';
import { RegisterReceiverComponent } from '../dialogs/register-receiver/register-receiver.component';
import { ReceiversService } from '../_services/receivers.service';
import { PaymentsService } from '../_services/payments.service';
import { ConfirmationService } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { PaymentStatusComponent } from '../dialogs/payment-status/payment-status.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private cs: ConfirmationService,
    private rs: ReceiversService,
    private ps: PaymentsService
  ) {}

  paymentForm = this.fb.group({
    amount: [''],
    item_name: [''],
    m_payment_id: [''],
    merchant_id: [''],
    merchant_key: [''],
    return_url: [''],
    cancel_url: [''],
  });

  createPaymentForm = this.fb.group({
    receiver_unique_id: ['', [Validators.required]],
    receiver_id: [null, [Validators.required]],
    amount: [0, [Validators.required, Validators.min(5)]],
  });

  currentProfile$: BehaviorSubject<any> = new BehaviorSubject(null);
  paymentIntent$: BehaviorSubject<any> = new BehaviorSubject(null);
  payfastUrl$: BehaviorSubject<any> = new BehaviorSubject('');

  ngOnInit(): void {
    this.payfastUrl$.next(environment.pfUrl);

    // console.log('Payfast URL', this.payfastUrl$.value);
    this.route.params.subscribe((params) => {
      console.log(params);
      if (params['rec_unique_id']) {
        // retrieve the profile from the API or display message that the profile does not exist
        this.rs
          .getReceiverByUniqueId(params['rec_unique_id'])
          .subscribe((receiver) => {
            console.log('Receiver', receiver);
            this.currentProfile$.next(receiver);
            if (!receiver.registered) {
              this.showRegisterReceiverDialog();
            } else {
              this.currentProfile$.next(receiver);
              this.createPaymentForm.patchValue({
                receiver_unique_id: receiver.unique_id,
                receiver_id: receiver.id,
              });

              this.paymentForm.patchValue({
                merchant_id: environment.pfMerchantId,
                merchant_key: environment.pfMerchantKey,
                return_url: `${environment.appUrl}/#/${this.currentProfile$.value?.unique_id}?payment_status=success`,
                cancel_url: `${environment.appUrl}/#/${this.currentProfile$.value?.unique_id}?payment_status=failed`,
              });
            }
          });
      }
    });

    this.route.queryParams.subscribe((params) => {
      if (params['payment_status']) {
        this.openPaymentStatusDialog(params['payment_status']);
      }
    });
  }

  openOwnAmmountDialog($event: any) {
    // open dynamic dialog
    const ref = this.dialogService.open(OwnAmountDialogComponent, {
      header: 'Enter your own amount',
      width: '90%',
      height: '80%',
      data: {},
    });

    ref.onClose.subscribe((data) => {
      console.log('Data', data);
      this.createPaymentIntent(data.amount);
    });
  }

  openRegisterReceiverDialog($event: MouseEvent) {
    const ref = this.dialogService.open(RegisterReceiverComponent, {
      header: 'Register as a Receipient',
      width: '50%',
      height: '50%',
      data: { id: this.currentProfile$.value.id },
    });

    ref.onClose.subscribe((data) => {
      console.log('Data', data);
    });
  }

  showRegisterReceiverDialog() {
    const ref = this.dialogService.open(RegisterReceiverComponent, {
      header: 'Register as a Receipient',
      width: '90%',
      height: '80%',
      data: { receiver: this.currentProfile$.value },
    });

    ref.onClose.subscribe((data) => {
      console.log('Data', data);
      this.rs
        .updateReceiverByUniqueId(data.unique_id, data)
        .subscribe((res) => {
          console.log('Receiver updated', res);
        });
    });
  }

  openPaymentStatusDialog(status: String) {
    if (!status) {
      return;
    }
    let payment_header = '';
    if (status === 'success') {
      payment_header = 'Payment Successful';
    }
    if (status === 'failed') {
      payment_header = 'Payment Failed';
    }
    const ref = this.dialogService.open(PaymentStatusComponent, {
      header: payment_header,
      width: '90%',
      height: '50%',
      data: { status: status },
    });

    ref.onClose.subscribe((data) => {
      console.log('Data', data);
      this.router.navigate(['/', this.currentProfile$.value.unique_id]);
    });
  }

  createPaymentIntent(value: number) {
    console.log('Creating payment intent', value);
    this.createPaymentForm.patchValue({ amount: value });

    this.cs.confirm({
      message: 'Are you sure you want to proceed with the payment?',
      header: 'Payment Confirmation',
      target: document.getElementById('paymentDialog') as HTMLElement,
      icon: 'pi pi-info-circle',

      accept: () => {
        console.log('Payment confirmed');
        this.ps.createPayment(this.createPaymentForm.value).subscribe((res) => {
          this.paymentIntent$.next(res);
          console.log('Payment intent created', this.paymentIntent$.value);
          this.submitForm(this.paymentIntent$.value);
        });
      },
      reject: () => {
        console.log('Payment rejected');
        this.paymentIntent$.next(null);
      },
      key: 'paymentDialog',
    });
  }

  submitForm(paymentIntent: any) {
    this.paymentForm.patchValue({
      amount: String(paymentIntent.total_amount),
      item_name: paymentIntent.internal_reference,
      m_payment_id: paymentIntent.internal_reference,
    });

    // console.log('Payment form', this.paymentForm.value);

    (document.getElementById('payment') as HTMLFormElement).submit();
  }
  getFormControls(formGroup: FormGroup) {
    const keys = Object.keys(formGroup && formGroup.controls);
    // console.log('Keys', keys);
    return keys;
  }
}
