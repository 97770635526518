<div class="max-h-screen">
  <div
    class="px-4 py-3 md:px-6 lg:px-8 min-h-full h-full max-h-full"
    style="height: 100vh; min-height: 100%"
  >
    <span> </span>
    <div
      class="surface-card shadow-2 border-round-xl p-5 min-h-full h-full flex-row flex align-items-center justify-content-center"
      style="font-family: 'Outfit'"
    >
      <img
        class="flex px-3"
        src="assets/kwiktappay-horisontal.svg"
        height="48px"
      />
      <!-- <span class="flex text-600 text-3xl text-green-600">kwikTAPpay</span> -->
    </div>
  </div>
  <ng-container *ngIf="currentProfile$ | async as currentProfile; else loading">
    <div class="px-4 py-3 md:px-6 lg:px-8 min-h-full h-full max-h-full">
      <div class="surface-card shadow-2 border-round-xl p-5 min-h-full h-full">
        <div
          class="flex flex-wrap row-gap-3 align-items-center justify-content-between mb-5"
        >
          <div class="flex align-items-center">
            <span>
              <!-- <img src="assets/images/blocks/avatars/circle/avatar-f-4.png" /> -->
            </span>
            <div class="ml-3">
              <div class="text-900 text-xl mb-1">
                {{ currentProfile.first_name }} {{ currentProfile.last_name }}
              </div>
              <div class="text-600">Private</div>
            </div>
          </div>
          <button
            *ngIf="false"
            pButton
            pRipple
            label="Settings"
            class="p-button-outlined p-button-plain"
            (click)="openRegisterReceiverDialog($event)"
          ></button>
        </div>
        <ul
          class="list-none m-0 p-3 surface-100 flex flex-column lg:flex-row border-round"
        >
          <li class="flex-auto lg:border-bottom-none p-3 surface-border">
            <div class="flex flex-column align-items-center md:flex-row">
              <span>
                <!-- <img
              src="assets/images/blocks/pageheading/stat-1.svg"
              class="w-4rem"
            /> -->
              </span>
              <div class="mt-3 md:mt-0 ml-0 md:ml-3 text-center md:text-left">
                <!-- <div class="text-lg font-medium text-800 mb-2">Followers</div>
            <span class="text-600 white-space-nowrap">Tuesday, 432</span> -->
                <!-- <form
              action="https://sandbox.payfast.co.za/eng/process"
              method="post"
              target="_self"
              id="payment"
              [formGroup]="paymentForm"
            > DEV -->

                <form
                  action="https://www.payfast.co.za/eng/process"
                  method="post"
                  target="_self"
                  id="payment"
                  [formGroup]="paymentForm"
                >
                  <!-- <input
                    type="hidden"
                    name="return_url"
                    value="https://dev.kwikpay.io"
                  />

                  <input
                    type="hidden"
                    name="cancel_url"
                    value="https://dev.kwikpay.io"
                  /> -->
                  <input
                    type="hidden"
                    name="notify_url"
                    value="https://dev.kwikpay.io/api/payments/notify"
                  />
                  <!-- todo: get email address -->
                  <input
                    type="hidden"
                    name="email_address"
                    value="dittmarmax@gmail.com"
                  />

                  <ng-container
                    *ngFor="let pfControl of getFormControls(paymentForm)"
                  >
                    <input
                      type="hidden"
                      [formControlName]="pfControl"
                      name="{{ pfControl }}"
                      [value]="paymentForm.get(pfControl)?.value"
                    />
                  </ng-container>

                  <input type="submit" hidden />
                  <button
                    type="button"
                    class="mx-2 my-2 p-button-primary"
                    pButton
                    label="Pay R10.00*"
                    (click)="createPaymentIntent(10.0)"
                  ></button>
                  <button
                    type="button"
                    class="mx-2 my-2 p-button-primary"
                    pButton
                    label="Pay R20.00*"
                    (click)="createPaymentIntent(20.0)"
                  ></button>
                  <button
                    type="button"
                    class="mx-2 my-2 p-button-primary"
                    pButton
                    label="Pay R50.00*"
                    (click)="createPaymentIntent(50.0)"
                  ></button>
                  <button
                    type="button"
                    class="mx-2 my-2 p-button-primary"
                    pButton
                    label="Pay R100.00*"
                    (click)="createPaymentIntent(100.0)"
                  ></button>
                  <button
                    type="button"
                    class="mx-2 my-2 p-button-primary"
                    pButton
                    label="Enter Own Amount"
                    (click)="openOwnAmmountDialog($event)"
                  ></button>
                </form>
              </div>
            </div>
          </li>
          <li>
            <p class="text-600 text-lg text-center" style="font-size: 0.8rem">
              * excludes fees to ensure recipient receives full amount
            </p>
          </li>
        </ul>
      </div>
    </div>
    <p-confirmDialog
      key="paymentDialog"
      [breakpoints]="{ '960px': '75vw', '640px': '100vw' }"
      [style]="{ width: '50vw' }"
    >
      <!-- confirm payment -->
      Do you want to go ahead and pay?
      <div class="col-12 lg:col-3 p-3">
        <div class="surface-card p-4 border-round shadow-2">
          <div class="border-bottom-1 surface-border pb-4">
            <span class="text-900 text-xl block">Order Summary</span>
          </div>
          <div class="border-bottom-1 surface-border my-3 py-2">
            <div class="flex justify-content-between mb-3">
              <span class="text-900">Amount</span>
              <span class="text-900">$123.00</span>
            </div>
            <div class="flex justify-content-between mb-3">
              <span class="text-900">Fees</span>
              <span class="text-green-500">$12.00</span>
            </div>
          </div>
          <div class="border-bottom-1 surface-border my-3 py-2">
            <div class="flex justify-content-between mb-3">
              <span class="text-900 font-medium">Total</span>
              <span class="text-900 font-bold">$111.00</span>
            </div>
          </div>
        </div>
      </div>
    </p-confirmDialog>
  </ng-container>
  <ng-template #loading>
    <div class="flex flex-column align-items-center justify-content-center">
      <p-progressSpinner
        styleClass="spinner"
        class="m-8"
        strokeWidth="2"
      ></p-progressSpinner>
      <span class="text-600 text-lg">Loading...</span>
    </div>
  </ng-template>
</div>
