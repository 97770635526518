import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PrimeModule } from './prime.module';
import { ProfileComponent } from './profile/profile.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DialogService } from 'primeng/dynamicdialog';
import { OwnAmountDialogComponent } from './dialogs/own-amount-dialog/own-amount-dialog.component';
import { RegisterReceiverComponent } from './dialogs/register-receiver/register-receiver.component';
import { HttpClientModule } from '@angular/common/http';
import { ConfirmationService } from 'primeng/api';

@NgModule({
  declarations: [
    AppComponent,
    ProfileComponent,
    OwnAmountDialogComponent,
    RegisterReceiverComponent,
  ],
  imports: [
    FormsModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,

    AppRoutingModule,
    ReactiveFormsModule,
    PrimeModule,
  ],
  providers: [ConfirmationService, DialogService],
  bootstrap: [AppComponent],
})
export class AppModule {}
