import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaymentsService {
  constructor(private apiService: ApiService) {}

  createPayment(payment: any) {
    return this.apiService
      .postAPI('payments/create', payment)
      .pipe(map((res: any) => res.body));
  }
}
