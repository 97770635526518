import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { NONE_TYPE } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { catchError, Observable, repeat, shareReplay, throwError } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  httpFileOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'multipart/form-data',
    }),
  };

  getAPI<Type>(
    endpoint: string,
    queryParams?: any,
    pollInterval?: number
  ): Observable<any> {
    let parameters = {};
    if (pollInterval) {
      if (queryParams) {
        parameters = queryParams;
      }
      return this.http
        .get<Type>(`${environment.apiUrl}/${endpoint}`, {
          observe: 'response',
          responseType: 'json',
          params: parameters,
        })
        .pipe(
          catchError(this.handleError),
          // shareReplay(),
          repeat({ delay: pollInterval })
          // repeat({ delay: pollInterval, count: 1 })
        );
    } else {
      if (queryParams) {
        parameters = queryParams;
        // //console.log('parameters', parameters);
      }
      return this.http
        .get<Type>(`${environment.apiUrl}/${endpoint}`, {
          observe: 'response',
          responseType: 'json',
          params: parameters,
        })
        .pipe(catchError(this.handleError), shareReplay(1));
    }
  }

  postAPI<Type>(endpoint: string, data: any): Observable<any> {
    return this.http
      .post<Type>(`${environment.apiUrl}/${endpoint}`, data, {
        observe: 'response',
        responseType: 'json',
      })
      .pipe(catchError(this.handleError));
  }

  deleteAPI<Type>(endpoint: string, query_id: Number): Observable<any> {
    return this.http
      .delete<Type>(`${environment.apiUrl}/${endpoint}/${query_id}`, {
        observe: 'response',
        responseType: 'json',
      })
      .pipe(catchError(this.handleError));
  }

  deleteAllAPI<Type>(endpoint: string): Observable<any> {
    return this.http
      .delete<Type>(`${environment.apiUrl}/${endpoint}/`, {
        observe: 'response',
        responseType: 'json',
      })
      .pipe(catchError(this.handleError));
  }

  putAPI<Type>(endpoint: string, query_id: Number, data: any): Observable<any> {
    return this.http
      .put<Type>(`${environment.apiUrl}/${endpoint}/${query_id}`, data, {
        observe: 'response',
        responseType: 'json',
      })
      .pipe(catchError(this.handleError));
  }

  postFileAPI<Type>(endpoint: string, data: any): Observable<any> {
    return this.http
      .post<Type>(`${environment.apiUrl}/${endpoint}`, data, {
        observe: 'response',
        responseType: 'json',
      })
      .pipe(catchError(this.handleError));
  }

  getFileAPI<Type>(endpoint: string, query_id: string): Observable<any> {
    return this.http
      .get(`${environment.apiUrl}/${endpoint}/${query_id}`, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    console.log('error', error);

    if (error.error instanceof ErrorEvent) {
      // Client-side error
      errorMessage = `An error occurred: ${error.error.message}`;
    } else {
      // Server-side error
      errorMessage = `Server returned code: ${error.status}, error message is: ${error.error.message}`;
    }

    // Optionally handle specific error codes or show alerts
    if (error.status === 401) {
      console.error('Unauthorized error:', errorMessage);
      // You can handle unauthorized errors here, for example, redirecting to login page
      // Or emitting an event to inform the rest of the application
    } else {
      console.error('Error:', errorMessage);
    }

    // Bubble up the error message
    return throwError(error);
  }
}
